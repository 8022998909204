import * as FlexWebChat from "@twilio/flex-webchat-ui";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import moment from "moment";

export type UIState = "chat" | "outOfHours" | "confirm" | "confirmation";
export type HolidayState = "holidayDate" | "nonHolidayDate";
export type ChatState =
  | "preengagement"
  | "loading"
  | "chatting"
  | "ended"
  | "transcript"
  | "sent";
export type ButtonState = "displayed" | "hidden";

export interface AppStateContextType {
  uiState: UIState;
  setUiState(state: UIState): void;
  holidayState: HolidayState;
  setHolidayState(state: HolidayState): void;
  chatState: ChatState;
  setChatState(state: ChatState): void;
  outOfHours: boolean;
  setOutOfHours(state: boolean): void;
  flexWebChatManager: FlexWebChat.Manager | undefined;
  setFlexWebChatManager(manager: FlexWebChat.Manager): void;
  buttonState: ButtonState;
  setButtonState(state: ButtonState): void;
  brandName: string;
  setBrandName(state: string): void;
}

export const AppStateContext = createContext<AppStateContextType>(null!);

export default function AppStateProvider(props: PropsWithChildren<{}>) {
  const [uiState, setUiState] = useState<UIState>("chat");
  const [holidayState, setHolidayState] =
    useState<HolidayState>("nonHolidayDate");
  const [chatState, setChatState] = useState<ChatState>("preengagement");
  const [outOfHours, setOutOfHours] = useState<boolean>(false);
  const [manager, setManager] = useState<FlexWebChat.Manager>();
  const [buttonState, setButtonState] = useState<ButtonState>("displayed");
  const [brandName, setBrandName] = useState<string>("");
  useEffect(() => {
    fetch(
      `https://c2d-poc-4755.twil.io/get-race-schedule?schedule=webchat-general`
    )
      .then((res) => res.json())
      .then((data) => {
        if (!data.isMatch) {
          setOutOfHours(true);
          setUiState("outOfHours");
        }
      });

    const currentDate = moment();
    const startXmasHol = moment("2022-12-24", "YYYY MM DD");
    const endXmasHol = moment("2022-12-28", "YYYY MM DD");
    const startNYHol = moment("2022-12-31", "YYYY MM DD");
    startNYHol.set("hour", 17);
    const endNYHol = moment("2023-1-3", "YYYY MM DD");
    const tempOpen = moment("2023-1-2", "YYYY MM DD");
    tempOpen.set("hour", 9);
    const tempClosed = moment("2023-1-2", "YYYY MM DD");
    tempClosed.set("hour", 17);

    if (currentDate >= startXmasHol && currentDate <= endXmasHol) {
      setOutOfHours(true);
      setUiState("outOfHours");
      setHolidayState("holidayDate");
    }

    if (
      currentDate >= startNYHol &&
      currentDate <= endNYHol &&
      !(currentDate >= tempOpen && currentDate <= tempClosed)
    ) {
      setOutOfHours(true);
      setUiState("outOfHours");
      setHolidayState("holidayDate");
    }

    switch (true) {
      case window.location.hostname.includes("bristolstreet"):
        return setBrandName("Bristol Street Motors Advisor");
      case window.location.hostname.includes("macklinmotors"):
        return setBrandName("Macklin  Motors Advisor");
      case window.location.hostname.includes("vertumotors"):
        return setBrandName("Vertu Motors Advisor");
      case window.location.hostname.includes("vertumotorcycles"):
        return setBrandName("Vertu Motorcycles Advisor");
      case window.location.hostname.includes("carcreditassured"):
        return setBrandName("Credit Card Assured Advisor");
      case window.location.hostname.includes("taxicentre"):
        return setBrandName("Taxi Centre Advisor");
      case window.location.hostname.includes("vansdirect"):
        return setBrandName("Vansdirect Advisor");
      case window.location.hostname.includes("localhost"):
        return setBrandName("Local Host Advisor");
      default:
        return setBrandName("External");
    }
  }, [setOutOfHours, setUiState]);

  let contextValue = {
    uiState,
    setUiState,
    setHolidayState,
    holidayState,
    chatState,
    setChatState,
    outOfHours,
    setOutOfHours,
    flexWebChatManager: manager,
    setFlexWebChatManager: setManager,
    buttonState,
    setButtonState,
    setBrandName,
    brandName,
  };

  return (
    <AppStateContext.Provider value={{ ...contextValue }}>
      {props.children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be used within the AppStateProvider");
  }
  return context;
}
