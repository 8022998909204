import { Config } from "@twilio/flex-webchat-ui/src/state/AppConfig";

let siteName = "Bristol Street Motors";
switch (true) {
  case window.location.href.includes("macklinmotors"):
    siteName = "Macklin Motors";
    break;
  case window.location.href.includes("vertumotors"):
    siteName = "Vertu Motors";
    break;
  case window.location.href.includes("carcreditassured"):
    siteName = "Credit Card Assured";
    break;
  case window.location.href.includes("vansdirect"):
    siteName = "Vansdirect";
    break;
  case window.location.href.includes("vertumotorcycles"):
    siteName = "Vertu Motorcycles";
    break;
  case window.location.href.includes("taxicentre"):
    siteName = "Taxi Centre";
    break;
}

export const flexWebchatConfig: Config = {
  startEngagementOnInit: false,
  logLevel: "error",
  preEngagementConfig: {
    submitLabel: "Start a live chat",
    description: `Start a live chat with a ${siteName} specialist. To begin, enter your name below and click the start chat button.`,
    fields: [
      {
        type: "InputItem",
        attributes: {
          name: "Name",
          readOnly: false,
          placeholder: "Enter your name",
          type: "text",
          value: localStorage.getItem("c2d-forms-name") || "",
          required: true,
        },
        label: "",
      },
      // {
      // 	type: 'InputItem',
      // 	attributes: {
      // 		name: 'EmailAddress',
      // 		readOnly: false,
      // 		placeholder: 'Enter your e-mail address',
      // 		type: 'text',
      // 		value: localStorage.getItem('c2d-forms-email') || '',
      // 		required: true,
      // 	},
      // 	label: '',
      // },
    ],
  },
  // colorTheme: {
  // 	overrides: {
  // 		MainContainer: {
  // 			background: '#E0EBFA',
  // 		},
  // 		Chat: {
  // 			MessageListItem: {
  // 				FromMe: {
  // 					Header: {
  // 						color: '#fff',
  // 						background: '#5243aa',
  // 					},
  // 					Avatar: {
  // 						color: '#000',
  // 						background: '#5243aa',
  // 					},
  // 					Bubble: {
  // 						color: '#fff',
  // 						background: '#5243aa',
  // 					},
  // 				},
  // 			},
  // 		},
  // 		EntryPoint: {
  // 			Container: {
  // 				'&:hover': {
  // 					background: '#193F77',
  // 					backgroundBlendMode: 'color',
  // 				},
  // 				right: '30px',
  // 				color: '#FFFFFF',
  // 				background: '#193F77',
  // 				bottom: '30px',
  // 			},
  // 		},
  // 		PreEngagementCanvas: {
  // 			Form: {
  // 				SubmitButton: {
  // 					width: '100%',
  // 					height: '48px',
  // 					'border-radius': '8px',
  // 					color: '#FFFFFF',
  // 					background: '#193F77',
  // 				},
  // 			},
  // 			Container: {
  // 				background: '#E0EBFA',
  // 			},
  // 		},
  // 	},
  // },
  // context: {
  // 	friendlyName: 'You',
  // },
  componentProps: {
    MessagingCanvas: {
      memberDisplayOptions: {
        yourDefaultName: "You",
        yourFriendlyNameOverride: true,
      },
      showWelcomeMessage: false,
    },
    // MainHeader: {
    // 	padding: '10px',
    // 	showImage: false,
    // 	showTitle: false,
    // 	imageUrl: '',
    // },
    // MainContainer: {
    // 	width: '300px',
    // },
  },
  accountSid:
    window.location.href.includes("dev.") ||
    window.location.href.includes("localhost")
      ? "ACa5ccb23b3d287ba743d08e5afdaad1fa"
      : "ACc84619d33a93bb4a89a1e591832543e6",
  flexFlowSid:
    window.location.href.includes("dev.") ||
    window.location.href.includes("localhost")
      ? "FO387e44003aa7e8a756cc01b80471c19b"
      : "FO74a7fe792ee14b480bf050bee1afed4a",
};
